import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "メドレー知財担当がエンジニア・デザイナー向け社内勉強会で\"特許\"について発表しました",
  "date": "2020-05-22T08:39:02.000Z",
  "slug": "entry/2020/05/22/173902",
  "tags": ["medley"],
  "hero": "./2020_05_22.png",
  "heroAlt": "特許"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは、メドレーのコーポレート本部法務コンプライアンス部で知的財産関連の業務を担当している鬼鞍です。コーポレート本部といっても、知財担当の仕事内容としてはエンジニア、デザイナーの方々としっかり協働することが一番大事なので、テックブログにも僭越ながら登場させていただきました。`}</p>
    <p>{`先日、社内勉強会のテックランチにて、「特許」についてお話しする機会がありました。`}</p>
    <p>{`今回の勉強会は、コロナウイルスの影響で全員オンライン参加という状況下でうまく伝えられるか心配だったのですが、予想以上に質問を頂いてそれなりに反響がありましたので、ここで紹介させていただこうと思います（厳密には特許権のことですがここでは説明の便宜上、以下特許と称することにします）。`}</p>
    <p>{`みなさん、特許と聞いてどのようなイメージをもたれるでしょうか？`}</p>
    <p>{`あなたが知財を仕事にしている人ではない限り、多くの方にとっては馴染みの薄い世界かもしれません。今回は、世間ではあまり表に出てこない特許という世界にスポットライトを当てつつ、少しでも特許の面白さみたいなものをお伝えできればと思います。`}</p>
    <h1>{`特許とは陣地に敷かれた地雷でもあり、身を守る鎧でもある`}</h1>
    <p>{`さて、秒で特許のイメージをつかんでいただくためにここでは極端な例えをします。`}</p>
    <p>{`他人が取得した特許は陣地に置かれた地雷のようなものであり、この地雷を踏んでしまうと特許権侵害というリスクで怪我をしてしまいます。あまりいい例えではないかもしれませんが、これは特許が技術を独占的に実施し、他社が実施した場合には排他できるという側面を表現しています。`}</p>
    <p>{`企業の知財部門は、地雷を踏まないように日々特許調査をし、地雷のない安全な道を探る、いわば道先案内人のようなものなのです。`}</p>
    <p>{`また逆に、自分たちが取得した特許があれば、地雷を踏んで権利を主張されてもカウンターパンチを当てることができるので、身を守るための鎧にもなるわけです。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200522/20200522160511.jpg",
        "alt": "f:id:medley_inc:20200522160511j:plain",
        "title": "f:id:medley_inc:20200522160511j:plain"
      }}></img></p>
    <h1>{`特許とはエンジニア、デザイナーの成果物である`}</h1>
    <p>{`ここで、特許について少し目線を変えて見てみましょう。`}</p>
    <p>{`特許というものは、皆さんが努力・工夫した目に見えない技術的なアイディアが見える化された成果物である、という見方もできます。皆さんの頭の中にあるアイディアに知的財産権という形を与えることで、外部の人たちに明確に示すことができます。これによって、例えば投資家から高い評価を得たり、自分たちの技術力を PR するためのツールとして特許を用いることもできるのです。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200522/20200522152632.jpg",
        "alt": "f:id:medley_inc:20200522152632j:plain",
        "title": "f:id:medley_inc:20200522152632j:plain"
      }}></img></p>
    <h1>{`特許権は国から与えられるグッドアイディア賞みたいなもの`}</h1>
    <p>{`特許については知らなくてもグッドデザイン賞についてはご存知の方が多いかもしれません。`}</p>
    <p>{`メドレーのプロダクトであるクラウド診療システム「CLINICS」はグッドデザイン賞を受賞しています。`}</p>
    <p>{`「`}<a parentName="p" {...{
        "href": "https://www.g-mark.org/award/describe/49638"
      }}>{`クラウド診療支援システム [CLINICS] | 受賞対象一覧 | Good Design Award`}</a>{`」`}</p>
    <p>{`また、ISMS（情報セキュリティマネジメントシステム）についての認証も受けています。`}</p>
    <p>{`「`}<a parentName="p" {...{
        "href": "/entry/2019/02/01/172457"
      }}>{`全社で本気になってリーンに ISMS の仕組みをつくった話`}</a>{`」`}</p>
    <p>{`なぜ、こんな話をしているかというと、グッドデザイン賞も ISMS も特許権も 1 つの共通点があるからです。それはいずれも客観的な審査を経て合格したものにだけ与えられてるものだという点です。`}</p>
    <p>{`そして合格したものは、対外的に一定の信用を示すことができる、という機能を備えていいます。日本の特許庁の審査レベルは世界的に見ても高水準であると言われています。特許権というのは、その企業が技術的なアイディアに優れているという一定の信用を示すためのツールとしての性質も兼ね備えているのです。`}</p>
    <h1>{`特許に潜むリスク`}</h1>
    <p>{`先ほど特許は地雷であるという話をしました。より具体的に地雷を踏むとどんなリスクがあるのでしょうか？そのリスクは主に差し止め請求や損害賠償です。仮にこのような請求を受けてしまった場合は、大変な労力とコストが伴います。訴訟まで発展してしまうと、周囲の信用を失うことにもなりかねません。`}</p>
    <p>{`このようなリスクを最小限にするために、知財担当の私が各プロダクトごとの開発定例に同席して自社の開発動向をキャッチアップし、他社権利の特許調査を行っています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200522/20200522152754.jpg",
        "alt": "f:id:medley_inc:20200522152754j:plain",
        "title": "f:id:medley_inc:20200522152754j:plain"
      }}></img></p>
    <h1>{`特許的な思考プロセスとは本質を考えること`}</h1>
    <p>{`さて、ここで「特許的な思考プロセス」を使って皆さんに少し頭の体操をしていただこうと思います。`}</p>
    <p>{`知財関連ではよく例に取り上げられるのですが、鉛筆の発明を題材にして考えてみます。`}</p>
    <p>{`あなたは断面が円形の鉛筆を使っていましたが、机の上を転がって下へ落ちてしまうという問題点に気がつきました。あなたは、鉛筆の断面形状に着目し、断面を五角形にすることにより、この問題を解決したとしましょう。そして、「断面が五角形の鉛筆」という内容を特許請求の範囲（特許権の権利範囲部分を決めるもの）に記載して特許権を得ることができたとします。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200522/20200522152846.jpg",
        "alt": "f:id:medley_inc:20200522152846j:plain",
        "title": "f:id:medley_inc:20200522152846j:plain"
      }}></img></p>
    <p>{`しかし、その後、あなたの断面五角形の鉛筆を見て他の業者が断面を三角形や四角形にした鉛筆を思いつき、それが市場に多く流通し、あなたの断面五角形の鉛筆の売り上げが下がってしまいました。あなたは特許請求の範囲に何と記載すべきだったのでしょうか？`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200522/20200522152924.jpg",
        "alt": "f:id:medley_inc:20200522152924j:plain",
        "title": "f:id:medley_inc:20200522152924j:plain"
      }}></img></p>
    <p>{`そう、あなたは「断面が五角形の鉛筆」ではなく「断面が多角形の鉛筆」と書いていれば良かったのですね。`}</p>
    <p>{`そうすれば、三角形や四角形などの類似品の流通を防ぐことができました。これは簡単です。`}</p>
    <p>{`その後、あなたは断面が楕円形でも、机の上を転がりにくいという効果を生み出すことに気がつきます。では、多角形も楕円形も含めるためにはどのような表現がいいのでしょうか？ちょっと考えてみてください。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200522/20200522153019.jpg",
        "alt": "f:id:medley_inc:20200522153019j:plain",
        "title": "f:id:medley_inc:20200522153019j:plain"
      }}></img></p>
    <p>{`あくまで答えの一例ですが、「断面が非円形の鉛筆」とか、「断面がその中心からの距離である長軸と短軸とを有する鉛筆」などの表現であれば上記の全ての形状をカバーすることができます。`}</p>
    <p>{`このように、いくつかの具体例から、効果を生み出すために必要最低限な要素は何なのか、共通点は何なのかを探し出すことが、アイディアの本質を捉えることになります。具体と抽象の間を行ったり来たりするプロセスです。`}</p>
    <p>{`アイディアの本質を捉えるためには多くの具体例を出す発想力が必要です。`}</p>
    <p>{`もしあなたが発想力豊かなエンジニアやデザイナーであれば強力な特許権が取得できるかもしれません。実際にプロダクトに生かされていない技術的なアイディアというのはあなたの頭の中に埋もれていることが多いものです。`}</p>
    <h1>{`エンジニアやデザイナーの成果が報われる土壌作りに貢献したい`}</h1>
    <p>{`知的財産権の生みの親はエンジニアやデザイナーの皆さんです。皆さんはあまり意識していないかもしれませんが、技術的なアイディアは日々の開発業務で生み出されていて、それが形になるものもあればならないものもあります。`}</p>
    <p>{`メドレーでは、これまで検討された技術アイディアのうち、プロダクトとして実現化されたものや、そうでないものにかかわらず将来性を見込めそうなものについては、実際に特許出願をしてきました。`}</p>
    <p>{`・`}<a parentName="p" {...{
        "href": "https://www.j-platpat.inpit.go.jp/c1800/PU/JP-2019-185642/AF400254F45C09B0BC8C9BA831F8C3CB3C41740DF9A6E4E697B15FA9348785F1/11/ja"
      }}>{`ブロックチェーンを用いた電子処方箋の管理方法（特願 2018-078928）`}</a></p>
    <p>{`・`}<a parentName="p" {...{
        "href": "https://www.j-platpat.inpit.go.jp/c1800/PU/JP-2018-120430/E64C6A57EDBBA6D793A8F2BC7966E6C6A71D17228FF6D498D8BB7E3A7EFB89F0/11/ja"
      }}>{`症状チェッカー（特願 2017-011444）`}</a></p>
    <p>{`・患者統合基盤（特願 2019-233247）（出願中につき概念図を IR 資料より抜粋）`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200522/20200522153158.jpg",
        "alt": "f:id:medley_inc:20200522153158j:plain",
        "title": "f:id:medley_inc:20200522153158j:plain"
      }}></img></p>
    <p>{`また、メドレーでは、エンジニア、デザイナーの方々の成果物がプロダクトだけでなく、知的財産としてもきちんと社内外で認められ、それが名誉となるような土壌作りをしていこうと考えています。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`メドレーは、テクノロジーを使って医療分野のデジタル化を推進し、医療ヘルスケアの未来をつくる会社です。そんなメドレーで生み出される素晴らしい技術やデザインを知的財産という分野で可能な限りバックアップしてきたいと思っています。少しでも特許の世界を感じて頂ければ幸いです。最後までお付き合い頂きありがとうございました！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      